import {useState, useEffect} from "react";

import classes from "./DateLine.module.css";

const DateLine = () => {
	const [dateState, setDateState] = useState(Date.now());

	useEffect(() => {
		const interval = setInterval(() => {
			setDateState(Date.now());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const day = new Intl.DateTimeFormat("de-DE", {weekday: "long"}).format(dateState);
	const date = new Intl.DateTimeFormat("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}).format(dateState);

	return <div className={classes.DateLine}>Lübeck am <strong>{day}</strong>, den <strong>{date}</strong></div>;
};

export default DateLine;
