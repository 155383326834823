import * as actionTypes from "./actionTypes";

export const fetchNews = (articles) => {
	return {
		type: actionTypes.FETCH_NEWS,
		articles: articles.articles,
		leadArticles: articles.leadArticles,
		topArticle: articles.topArticle
	};
};


export const fetchNewsDetails = (article) => {
	return {
		type: actionTypes.FETCH_NEWS_DETAILS,
		article: article.articleDetails
	};
};
