import classes from "./Copyright.module.css";

const copyright = () => (
	<div className={classes.Copyright}>&copy; {new Date().getFullYear()} <a
		href="https://www.hl-live.de/"
		target="_blank"
		rel="noreferrer">hl-live.de</a> &amp; <a
		href="https://hl.julianstock.de/"
		target="_blank"
		rel="noreferrer">hl.julianstock.de</a></div>
);

export default copyright;
