import * as actionTypes from "./actionTypes";

const initialState = {
	articles: [],
	leadArticles: [],
	topArticle: null,
	article: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_NEWS:
			return {
				...state,
				articles: action.articles,
				leadArticles: action.leadArticles,
				topArticle: action.topArticle
			};
		case actionTypes.FETCH_NEWS_DETAILS:
			return {
				...state,
				article: action.article
			};
		default:
			return state;
	}
};

export default reducer;
