import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import List from "./List";
import Details from "./Details";
import Reloader from "./Reloader";
import * as API from "./API";
import Loading from "../Loading/Loading";
import {fetchNews, fetchNewsDetails} from "./store/actions";
import {connect} from "react-redux";

class News extends Component {
	state = {
		isLoaded: false,
		isReloading: false
	};

	reloadInterval = null;

	componentDidMount() {
		this.fetchEntries();
	}

	fetchEntries = () => {
		API.fetchAllEntries().then(({data}) => {
			const contentDiv = API.getContentDiv(data);
			const leadArticles = API.parseArticles(contentDiv.querySelectorAll("article.Kasten"), true);
			const topArticle = API.parseArticles(contentDiv.querySelectorAll("article.topstory"), false, true);
			const articles = API.parseArticles(contentDiv.querySelectorAll("article:not([class])"));

			this.props.onFetchNews({
				leadArticles,
				topArticle,
				articles
			});

			this.setState({
				isLoaded: true
			});
		}).catch(err => {
			console.log(err);

			this.setState({
				isLoaded: true
			});
		});
	};

	toggleReload = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				isReloading: !prevState.isReloading
			};
		}, () => this.updateReloadInterval());
	};

	updateReloadInterval = () => {
		if (this.state.isReloading) {
			this.fetchEntries();

			this.reloadInterval = setInterval(() => {
				this.fetchEntries();
			}, 300000);
		}
		else {
			clearInterval(this.reloadInterval);
			this.reloadInterval = null;
		}
	};

	getContent = () => {
		const articles = this.props.articles;
		const topArticle = this.props.topArticle;
		const leadArticles = this.props.leadArticles;
		const article = this.props.article;

		let content = <Loading />;
		if (this.state.isLoaded) {
			content = (
				<>
					<Reloader
						toggleReload={this.toggleReload}
						reloadActive={this.state.isReloading} />

					<Switch> <Route
						path="/"
						exact
						render={(props) => <List {...props}
							articles={articles}
							leadArticles={leadArticles}
							topArticle={topArticle} />}
					/>

						<Route
							path="/post/:title"
							render={(props) => <Details {...props}
								articles={articles}
								topArticle={topArticle}
								article={article}
								fetchNewsDetails={(article) => this.props.onFetchNewsDetails(article)} />}
						/> </Switch>
				</>
			);
		}

		return content;
	};

	render = () => this.getContent();
}

const mapStateToProps = state => {
	return {
		articles: state.articles,
		leadArticles: state.leadArticles,
		topArticle: state.topArticle,
		article: state.article
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onFetchNews: (articles) => {
			dispatch(fetchNews(articles));
		},
		onFetchNewsDetails: (article) => {
			dispatch(fetchNewsDetails(article));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
