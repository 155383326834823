import ListEntry from "./ListEntry";
import classes from "./List.module.css";

export const list = props => (
	<>
		{props.topArticle
			? <ListEntry
				article={props.topArticle}
				isTopArticle />
			: null}

		{props.leadArticles.length > 0
			?
			<div className={classes.LeadArticles}>
				{props.leadArticles.map((art) => (
					<ListEntry
						key={art.articleId}
						article={art}
						category={art.category}
					/>
				))}
			</div>
			: null}

		{props.articles.map((art) => (
			<ListEntry
				key={art.articleId}
				article={art} />
		))}
	</>
);

export default list;
