import classes from "./Reloader.module.css";

const reloader = (props) => {
	const reloaderClasses = [classes.Reloader];

	if (props.reloadActive) {
		reloaderClasses.push(classes.active);
	}

	return (
		<div className={reloaderClasses.join(" ")}>
			<button
				onClick={props.toggleReload}
			>Autoreload: {props.reloadActive
				? "Aktiv"
				: "Aus"}</button>
		</div>
	);
};

export default reloader;
