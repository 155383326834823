import React, {Component} from "react";
import {withRouter} from "react-router";
import classes from "./Details.module.css";
import Loading from "../Loading/Loading";
import * as API from "./API";

class Details extends Component {
	state = {
		isLoaded: false
	};

	componentDidMount() {
		this.fetchArticleDetails();
	}

	parseParagraphs = (html, onlyImagecaption = false) => {
		const paragraphs = [];

		const htmlNodes = new DOMParser().parseFromString(html, "text/html").body.childNodes;
		let stopped = false;
		let imageCaption = null;
		htmlNodes.forEach(node => {
			if (node.nodeName === "HR") {
				stopped = true;
			}

			if (!stopped && (node.nodeName === "P" || node.nodeName === "STRONG")) {
				if (!onlyImagecaption && !node.classList.contains("mittig")) {
					paragraphs.push(this.getOnlyText(node));
				}
				else {
					if (onlyImagecaption && node.classList.contains("mittig")) {
						imageCaption = node.innerHTML;
					}
				}
			}
		});

		return !onlyImagecaption
			? paragraphs
			: imageCaption;
	};

	getOnlyText = (htmlNode) => {
		let text = "";

		htmlNode.childNodes.forEach(node => {
			if (node.nodeName === "#text") {
				text += node.textContent;
			}
		});

		return text;
	};

	parseAdditionalImages = (html) => {
		const images = [];

		const htmlNodes = new DOMParser().parseFromString(html, "text/html").body.childNodes;
		htmlNodes.forEach(node => {
			if (node.nodeName === "P") {
				const imageNodes = node.childNodes;
				imageNodes.forEach(tryImage => {
					if (tryImage.nodeName === "IMG") {
						images.push(tryImage.getAttribute("data-src"));
					}
				});
			}
		});

		return images;
	};

	fetchArticleDetails = () => {
		const articles = [];
		articles.push(this.props.topArticle);
		this.props.articles.map(a => articles.push(a));

		const article = articles.find(a => a.detailUrl === this.props.match.params.title);

		if (article) {
			API.fetchArticleDetails(article.articleId).then(({data}) => {
				const contentDiv = API.getContentDiv(data);

				const articleDetails = {...article};
				articleDetails["paragraphs"] = this.parseParagraphs(contentDiv.querySelectorAll("h1")[0].parentNode.innerHTML);
				articleDetails["imgCaption"] = this.parseParagraphs(contentDiv.querySelectorAll("h1")[0].parentNode.innerHTML, true);
				articleDetails["additionalImages"] = this.parseAdditionalImages(contentDiv.querySelectorAll("h1")[0].parentNode.innerHTML);

				this.props.fetchNewsDetails({articleDetails});

				this.setState({
					isLoaded: true
				});
			}).catch(err => {
				console.log(err);

				this.setState({
					isLoaded: true
				});
			});
		}
		else {
			this.setState({
				isLoaded: true
			});
		}
	};


	getContent = () => {
		const articleContent = (
			<>
				{this.props.article
					?
					<>
						<h1>{this.props.article.title}</h1>

						{this.props.article.additionalImages.length <= 1
							? this.props.article.additionalImages.map((i, index) => {
								return <img
									src={i}
									alt={this.props.article.title}
									className={classes.imageRight}
									key={index} />;
							})
							: <div className={classes.imageBlock}>{this.props.article.additionalImages.map((i, index) => {
								return <img
									src={i}
									alt={this.props.article.title}
									className={classes.imageRight}
									key={index} />;
							})}</div>}

						{this.props.article.paragraphs.map((p, index) => {
							return index === 0
								? <p key={index}><strong
									dangerouslySetInnerHTML={{__html: p}}></strong></p>
								: <p
									dangerouslySetInnerHTML={{__html: p}}
									key={index}></p>;
						})}

						{this.props.article.img
							?
							<div style={{clear: "both"}}>
								<img
									src={this.props.article.img}
									alt={("" + this.props.article.imgCaption).replace(/<\/?[^>]+(>|$)/g, "")} />
								<p dangerouslySetInnerHTML={{__html: this.props.article.imgCaption}}></p>
							</div>

							: null}</>
					: null}
			</>
		);

		return (
			<div className={classes.Details}>
				{
					this.state.isLoaded
						?
						<div className={classes.Entry}>
							{this.props.article
								?
								<>
									{articleContent}
								</>
								: <p className={classes.alignCenter}>Artikel konnte nicht geladen werden.</p>
							}
						</div>
						: <Loading />
				}
			</div>
		);
	}
;

render = () => this.getContent();
}

export default withRouter(Details);
