import {Component} from "react";
import DateLine from "./DateLine/DateLine";
import Copyright from "./Copyright/Copyright";
import classes from "./App.module.css";
import {BrowserRouter, Link} from "react-router-dom";
import News from "./News/News";
import {Provider} from "react-redux";
import {createStore} from 'redux';
import reducer from "./News/store/reducer";

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

class App extends Component {
	render = () => {
		return (
			<Provider store={store}> <BrowserRouter>
				<div className={classes.App}>
					<div className={classes.Logo}>
						<Link to={"/"}> <img
							src="https://www.hl-live.de/img/testlogo1110.png.pagespeed.ce.NoMlup8Lyr.png"
							alt="hl-live.de Logo" /> </Link>
					</div>

					<div className={classes.PageTitle}><span>X</span><br /><strong>react.js</strong></div>

					<DateLine />

					<News />

					<Copyright />
				</div>
			</BrowserRouter> </Provider>
		);
	};
}

export default App;
