import classes from "./ListEntry.module.css";
import {Link} from "react-router-dom";

const listEntry = (props) => {
		const entryClasses = [classes.Entry];

		if (props.article.category) {
			entryClasses.push(classes.LeadArticle);
		}

		if (props.isTopArticle) {
			entryClasses.push(classes.TopArticle);
		}

		return (
			<div className={entryClasses.join(" ")}>
				<Link to={"/post/" + props.article.detailUrl}>
					<figure className={classes.PreviewImage}>
						<img
							src={props.article.img}
							alt={props.article.title} />
					</figure>
				</Link>

				<h2><Link to={"/post/" + props.article.detailUrl}>{props.article.title}</Link></h2>

				{props.article.category
					?
					<div className={classes.Category}><span>Kategorie: </span>{props.article.category}</div>
					: null
				}

				<div>
					{props.article.preview}
				</div>
			</div>
		);
	}
;

export default listEntry;
