import axios from "axios";
import * as urlSlug from "url-slug";

const fetchAllEntries = () => axios.get("https://hl.julianstock.de/hl-live.php");

const fetchArticleDetails = (articleId) => axios.get("https://hl.julianstock.de/hl-live.php?id=" + articleId);

const getContentDiv = (data) => {
	let contentDiv = null;
	const parsedHTML = document.createElement("div");
	parsedHTML.innerHTML = data.trim();
	const allDivs = parsedHTML.querySelectorAll("div");
	allDivs.forEach(div => {
		if (div.getAttribute("id") === "flex-container") {
			contentDiv = div;
		}
	});

	return contentDiv;
};

const parseArticles = (articleNodes, hasCategory = false, isTopStory = false) => {
	let articles = [];

	articleNodes.forEach(art => {
		const titleElement = art.getElementsByTagName("a")[0];
		const imageElement = art.getElementsByTagName("img")[0];

		let imageAttributes = "";
		if (imageElement) {
			imageAttributes = Object.values(imageElement.attributes);
		}

		const article = {
			title: parseArticleTitle(titleElement, hasCategory),
			img: parseArticleImage(imageAttributes),
			preview: parseArticlePreview(art.getElementsByTagName("a")[isTopStory
				? 1
				: 0], hasCategory),
			category: hasCategory
				? parseArticleTitle(titleElement, hasCategory, true)
				: null,
			articleId: parseArticleId(titleElement),
			detailUrl: parseArticleDetailUrl(parseArticleTitle(titleElement, hasCategory))
		};

		if (article.title) {
			articles.push(article);
		}
	});

	return articles.length > 1
		? articles
		: articles[0];
};

const parseArticleTitle = (element, hasCategory = false, titleAsCategory = false) => {
	if (hasCategory && !titleAsCategory) {
		return element && element.lastChild.textContent
			? element.lastChild.textContent
			: null;
	}

	return element && element.firstChild.textContent
		? element.firstChild.textContent
		: null;
};

const parseArticleImage = (attributes) => {
	if (!attributes) {
		return;
	}

	const element = attributes.filter(att =>
		att.name === "data-src" || att.name === "src"
	)[0];

	return element
		? element.value.replace("/klein/", "/bilder/")
		: "";
};

const parseArticlePreview = (element, hasCategory = false) => {
	if (hasCategory) {
		return null;
	}

	return element && element.lastChild.textContent
		? element.lastChild.textContent
		: null;
};

const parseArticleId = element => element
	? parseInt(new URL("http://" + element.getAttribute("href")).searchParams.get("id"), 10)
	: null;

const parseArticleDetailUrl = title => title
	? urlSlug.convert(title, {
		dictionary: {
			"ß": "ss",
			"ä": "ae",
			"ö": "oe",
			"ü": "ue"
		},
		camelCase: false
	})
	: "";

export {fetchAllEntries, fetchArticleDetails, getContentDiv, parseArticles};
